<template>
    <div>
<!--        <page-title :titie_name="$t('m.customer.kehuxianqing')"></page-title>-->

        <div class="table_box">
            <div style="background: white;padding-left: 20px;padding-top: 10px;padding-bottom: 10px;margin-bottom: 20px;border: 1px #eeeeee solid;border-radius: 4px">
                <el-row align="middle" type="flex" justify="end">
                    <el-button size="small" type="primary" style="margin-right: 16px;" @click="handleReturn">返回</el-button>
                </el-row>
                <el-row align="middle" type="flex" class="table-title">
                    <el-col style="width: 100px;text-align: left">
                        <div class="keyword">
                            <span>{{$t("m.customer.jibenxinxi")}}</span>
                        </div>
                    </el-col>
                </el-row>
                <el-row type="flex">
                    <el-col style="width: 90px">
<!--                        <div class="goods-image-box" :style="'background-image: url('+ result.avatar +')'">-->
<!--                        </div>-->
                    </el-col>
                    <el-col>
                        <el-row class="item">
                            <el-col :span="12">
                                <span>{{$t("m.customer.gongsi")}}：{{result.name}}</span>
                            </el-col>
                            <el-col :span="12">
                                <span>{{$t("m.customer.zhuceren")}}：{{result.createAccountName}}</span>
                            </el-col>
                        </el-row>
                        <el-row class="item">
                            <el-col :span="12">
                                <span>{{$t("m.customer.tuandui")}}：{{scaleList[result.scale]}}</span>
                            </el-col>
                            <el-col :span="12">
                                <span>{{$t("m.customer.zhuceshijian")}}：{{result.createTime}}</span>
                            </el-col>
                        </el-row>
                        <el-row class="item">
                            <el-col :span="12">
                                <span>{{$t('m.customer.hangye')}}：
                                    <span v-for="(item,index) in industryList" :key="index" v-if="item.value==result.industry">
                                        {{item.label}}
                                    </span>
                                </span>
                            </el-col>
<!--                            <el-col :span="12">-->
<!--                                <span>{{$t("m.customer.laiyuanqudao")}}：{{result.channel}}</span>-->
<!--                            </el-col>-->
                        </el-row>
                    </el-col>
                </el-row>
            </div>

            <div style="background: white;padding-left: 20px;padding-top: 10px;padding-bottom: 10px;margin-bottom: 20px;border: 1px #eeeeee solid;border-radius: 4px">
                <el-row align="middle" type="flex" class="table-title">
                    <el-col style="width: 100px;text-align: left">
                        <div class="keyword">
                            <span>{{$t("m.customer.chengyuanxinxi")}}</span>
                        </div>
                    </el-col>
                </el-row>
                <el-row class="item header">
                    <el-col :span="10">
                        <span>{{$t('m.customer.chengyuan')}}</span>
                    </el-col>
                    <el-col :span="8">
                        <span>{{$t("m.customer.jiarushijian")}}</span>
                    </el-col>
                    <el-col :span="6">
                        <span>{{$t("m.customer.role")}}</span>
                    </el-col>
                </el-row>
                <el-row class="item" v-for="(item,index) in memberList" :key="index">
                    <el-col :span="10">
                        <span v-if="item.name" style="display: flex;align-items: center">
                            <img v-if="item.photoUrl!=null" :src="imageUrl(item.photoUrl)" class="photoUrl"/>
                            <div v-else class="photoUrl">
                                <img src="../../../assets/edit-imgs/making/default-icon.png" />
                            </div>
                            <el-link :underline="false" @click="goUser(item)">
                                <div style="line-height: 20px;">{{item.name}}</div>
                                <div style="line-height: 20px;">{{item.phone}}<span v-if="item.email&&item.phone">/</span>{{item.email}}</div>
                            </el-link>
                        </span>
                        <span v-else>-</span>
                    </el-col>
                    <el-col :span="8">
                        <span v-if="item.createTime">{{item.createTime}}</span>
                        <span v-else>-</span>
                    </el-col>
                    <el-col :span="6">
                        <span v-if="item.role">{{roleList[item.role]}}</span>
                        <span v-else>-</span>
                    </el-col>
                </el-row>
                <el-row type="flex" style="justify-content: flex-end;margin: 15px 0 0;">
                    <el-pagination class="pagination" background
                                   layout="total,prev, pager, next"
                                   :total="memberTotal" :page-size="5"
                                   @current-change='current_memberChange' style="margin-top: 10px">
                    </el-pagination>
                </el-row>
            </div>

            <div style="background: white;padding-left: 20px;padding-top: 10px;padding-bottom: 10px;margin-bottom: 20px;border: 1px #eeeeee solid;border-radius: 4px">
                <el-row align="middle" type="flex" class="table-title">
                    <el-col style="width: 100px;text-align: left">
                        <div class="keyword">
                            <span>{{$t("m.customer.minixinxi")}}</span>
                        </div>
                    </el-col>
                </el-row>
                <el-row class="item header">
                    <el-col :span="3">
                        <span>{{$t('m.customer.xiaochengxu')}}</span>
                    </el-col>
                    <el-col :span="4">
                        <span>{{$t("m.customer.createdTime")}}</span>
                    </el-col>
                    <el-col :span="3">
                        <span>{{$t("m.customer.createder")}}</span>
                    </el-col>
                    <el-col :span="3">
                        <span>{{$t("m.customer.zhaungtai")}}</span>
                    </el-col>
                    <el-col :span="3">
                        <span>{{$t("m.customer.suihoufabu")}}</span>
                    </el-col>
                    <el-col :span="3">
                        <span>{{$t("m.customer.zuihoubanben")}}</span>
                    </el-col>
                    <el-col :span="3">
                        <span>操作</span>
                    </el-col>
                </el-row>
                <el-row class="item" v-for="(item,index) in miniProList" :key="index">
                    <el-col :span="3">
                        <span v-if="item.name" style="display: flex;align-items: center">
                            <img v-if="item.logoUrl!=null" :src="imageUrl(item.logoUrl)" class="photoUrl"/>
                            <div v-else class="photoUrl">
                                <img src="../../../assets/edit-imgs/making/default-icon.png" />
                            </div>
                            <router-link class="preview"
                                         :to="{path:'/manager/preview',query:{id:item.id}}"
                                         target="_blank">{{item.name}}</router-link>
                        </span>
                        <span v-else>-</span>
                    </el-col>
                    <el-col :span="4">
                        <span v-if="item.createTime">{{item.createTime}}</span>
                         <span v-else>-</span>
                    </el-col>
                    <el-col :span="3">
                        <span v-if="item.createAccountName">{{item.createAccountName}}</span>
                         <span v-else>-</span>
                    </el-col>
                    <el-col :span="3">
                        <span v-if="item.status">{{statesList[item.status]}}</span>
                        <span v-else>-</span>
                    </el-col>
                    <el-col :span="3">
                        <span v-if="item.wechatReleaseTim">{{item.wechatReleaseTime}}</span>
                         <span v-else>-</span>
                    </el-col>
                    <el-col :span="3">
                        <span v-if="item.version">{{item.version}}</span>
                         <span v-else>-</span>
                    </el-col>
                    <el-col :span="3">
                        <el-button v-if="item.deleted==0" @click="handleDel(item,'删除')" type="text">删除</el-button>
                        <el-button v-if="item.deleted==1" @click="handleDel(item,'恢复')" type="text">恢复</el-button>
                    </el-col>
                </el-row>
                <el-row type="flex" style="justify-content: flex-end;margin: 15px 0 0;">
                    <el-pagination class="pagination" background
                                   layout="total,prev, pager, next"
                                   :total="miniProTotal" :page-size="5"
                                   @current-change='current_miniProChange' style="margin-top: 10px">
                    </el-pagination>
                </el-row>
            </div>

            <div style="background: white;padding-left: 20px;padding-top: 10px;padding-bottom: 10px;margin-bottom: 20px;border: 1px #eeeeee solid;border-radius: 4px">
                <el-row align="middle" type="flex" class="table-title">
                    <el-col style="width: 200px;text-align: left">
                        <div class="keyword">
                            <span>{{$t("m.customer.jiaoyixinxi")}}</span>
                            <a @click="order" style="color: #ff6f33;text-decoration: underline;cursor: pointer">{{$t("m.customer.leijidingdan")}}</a>
                        </div>
                    </el-col>
                </el-row>
                <el-row type="flex">
                    <el-col :span="6">
                        <el-row align="middle" type="flex">
                            <el-col style="width: 3px">
                            </el-col>
                            <el-col style="margin-left: 20px;opacity: 0.7">
                                <span>{{$t("m.customer.leijixiaofei")}}</span>
                            </el-col>
                        </el-row>
                        <el-row align="middle" type="flex">
                            <el-col style="width: 3px">
                            </el-col>
                            <el-col style="margin-left: 20px;margin-top: 6px">
                                <span style="color: #444444;font-size: 16px;font-weight: 600">{{orderInfo.totalAmount}}</span>
                            </el-col>
                        </el-row>
                    </el-col>
                    <el-col :span="6">
                        <el-row align="middle" type="flex">
                            <el-col style="width: 3px">
                            </el-col>
                            <el-col style="margin-left: 20px;opacity: 0.7">
                                <span>{{$t("m.customer.leijixaiofeidanshu")}}</span>
                            </el-col>
                        </el-row>
                        <el-row align="middle" type="flex">
                            <el-col style="width: 3px">
                            </el-col>
                            <el-col style="margin-left: 20px;margin-top: 6px">
                                <span style="color: #444444;font-size: 16px;font-weight: 600">{{orderInfo.COUNT}}</span>
                            </el-col>
                        </el-row>
                    </el-col>
                    <el-col :span="6">
                        <el-row align="middle" type="flex">
                            <el-col style="width: 3px">
                            </el-col>
                            <el-col style="margin-left: 20px;opacity: 0.7">
                                <span>{{$t("m.customer.kedanjia")}}</span>
                            </el-col>
                        </el-row>
                        <el-row align="middle" type="flex">
                            <el-col style="width: 3px">
                            </el-col>
                            <el-col style="margin-left: 20px;margin-top: 6px">
                                <span style="color: #444444;font-size: 16px;font-weight: 600">{{orderInfo.ARPA}}</span>
                            </el-col>
                        </el-row>
                    </el-col>
                    <el-col :span="6">
                        <el-row align="middle" type="flex">
                            <el-col style="width: 3px">
                            </el-col>
                            <el-col style="margin-left: 20px;opacity: 0.7">
                                <span>{{$t("m.customer.zuijinxaidan")}}</span>
                            </el-col>
                        </el-row>
                        <el-row align="middle" type="flex">
                            <el-col style="width: 3px">
                            </el-col>
                            <el-col style="margin-left: 20px;margin-top: 6px">
                                <span style="color: #444444;font-size: 16px;font-weight: 600">{{orderInfo.last_time}}</span>
                            </el-col>
                        </el-row>
                    </el-col>
                </el-row>
<!--                <el-table :data="orderList">-->
<!--                    <el-table-column property="orderNo" :label="$t('m.order.dingdanno')"></el-table-column>-->
<!--                    <el-table-column property="createTime" :label="$t('m.order.xaidanshijian')"></el-table-column>-->
<!--                    <el-table-column property="templateName" :label="$t('m.order.template')"></el-table-column>-->
<!--                    <el-table-column property="discountAmount" :label="$t('m.order.couponName')"></el-table-column>-->
<!--                    <el-table-column property="channel" :label="$t('m.order.payType')"></el-table-column>-->
<!--                    <el-table-column property="totalAmount" :label="$t('m.order.dingdanjine')"></el-table-column>-->
<!--                    <el-table-column property="status" :label="$t('m.order.dingdanzhuangtai')"></el-table-column>-->
<!--                    <el-table-column property="invoiceStatus" :label="$t('m.order.fapiaozhuangtai')"></el-table-column>-->
<!--                </el-table>-->
<!--                <el-row type="flex" style="justify-content: flex-end;margin: 15px 0 0;">-->
<!--                    <el-pagination class="pagination" background-->
<!--                                   layout="total,prev, pager, next"-->
<!--                                   :total="orderTotal" :page-size="5"-->
<!--                                   @current-change='current_orderChange' style="margin-top: 10px">-->
<!--                    </el-pagination>-->
<!--                </el-row>-->
            </div>
        </div>
    </div>
</template>

<script>
    import {
        getMerchantByMerchantId,
        getMerchantAccountList,
        getMiniProListByMerchantId,
        getTemplateOrderList,
        getMerchantOrderInfo,
        deleteMiniPro,
    } from 'request/api/client_list.js'
    import {getImageUrl} from "../../../utils/ImageUtils.js";

    export default {
        props:{
            curretId:String,
        },
        data() {
            return {
                //基本信息
                result: {},
                //成员信息
                memberList:[],
                memberPageNum:1,
                memberTotal:0,
                //小程序信息
                miniProList:[],
                miniProPageNum:1,
                miniProTotal:0,
                //团队规模
                scaleList:[
                    "1-30人","30-60人","60-100人"
                ],
                //所属行业
                industryList:[
                    {
                        label: '互联网',
                        value: '1'
                    },
                    {
                        label: '零售',
                        value: '2'
                    },
                    {
                        label: '地摊',
                        value: '3'
                    }
                ],
            //    订单信息
                orderList:[],
                orderPageNum:1,
                orderTotal:0,
                orderInfo:{
                    totalAmount:0,
                    COUNT:0,
                    ARPA:0,
                    last_time:0,
                },
                roleList:['所有者','企业成员', '管理者'],
                statesList:['未支付','已支付','已取消','已退款'],
            }
        },
        created() {
            this.queryClient()
            this.getAccountList()
            this.getMiniProList()
            // this.getOrderList()
            this.getOrderInfo()
        },
        methods: {
            handleReturn(){
                this.$emit('handleReturn')
            },
            imageUrl(url){
                return getImageUrl(url)
            },
            //成员
            goUser(item){
                this.$emit('goUser',item)
            },
            //累计订单
            order(e) {
                this.$router.push({path: '/manager/order/list', query: {customerId: this.curretId}})
            },
            queryClient() {
                getMerchantByMerchantId({
                    merchantId: this.curretId
                }).then(res => {
                    if (res.code == 200) {
                        if (res.code=200){
                            this.result = res.data
                        }
                    }
                })
            },
            //获取成员信息
            getAccountList() {
                getMerchantAccountList({
                    merchantId: this.curretId,
                    pageSize: 5,
                    pageNum: this.memberPageNum
                }).then(res => {
                    if (res.code == 200) {
                        this.memberList = res.data.list
                        this.memberTotal = res.data.total
                    }
                })
            },
            current_memberChange(e){
                this.memberPageNum = e
                this.getAccountList()
            },
        //    获取小程序信息
            getMiniProList(){
                getMiniProListByMerchantId({
                    merchantId: this.curretId,
                    pageSize: 5,
                    pageNum: this.miniProPageNum
                }).then(res=>{
                    if (res.code == 200) {
                        this.miniProList = res.data.list
                        this.miniProTotal = res.data.total
                    }
                })
            },
            current_miniProChange(e){
                this.miniProPageNum = e
                this.getMiniProList()
            },
            handleDel(item,text){
                let _this = this
                this.$confirm(`确定${text}?`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    deleteMiniPro({
                        miniProId:item.id
                    }).then(res=>{
                        if(res.code == 200){
                            this.$message({
                                type: 'success',
                                message: '操作成功'
                            });
                            this.getMiniProList()
                        }else{
                            this.$message({
                                type: 'error',
                                message: res.message
                            });
                        }
                    })
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消'
                    });
                });
            },
        //    订单信息
            getOrderList(){
                getTemplateOrderList({
                    merchantId:this.curretId,
                    pageSize: 5,
                    pageNum: this.orderPageNum,
                }).then(res=>{
                    if(res.code==200){
                        this.orderList = res.data.list
                        this.orderTotal = res.data.total
                    }
                })
            },
            current_orderChange(e){
                this.orderPageNum = e
                this.getOrderList()
            },
            getOrderInfo(){
                getMerchantOrderInfo({
                    merchantId:this.curretId,
                    status:1,//0创建订单(未支付)，1已支付,2已取消3,已退款
                }).then(res=>{
                    console.log(res)
                    if(res.code==200){
                        this.orderInfo = res.data
                    }
                })
            },
        },

    }
</script>

<style scoped lang="less">
    @import "../../../assets/style/index";
    .table_box {
        width: 100%;
        margin: auto;
    }

    .search_box {
        width: 100%;
        height: 50px;
        display: flex;
        align-items: center;
    }
    .table-title{
        background: #F6F6F6;
        height: 32px;
        margin-right: 16px;
        border-radius: 4px;
        border-left: 3px solid @color-primary;
        margin-top: 16px;
        margin-bottom: 16px;
    }
    .keyword {
        height: 50px;
        display: flex;
        align-items: center;
        margin-right: 10px;
    }

    .keyword span {
        width: 80px;
        padding-left: 10px;
        font-size: 16px;
        font-weight: 600;
        color: #333333;
    }

    .goods-image-box {
        width: 60px;
        height: 60px;
        align-content: center;
        border-radius: 60px;
        margin-left: 10px;
        background-size: contain;
        background-repeat: no-repeat;
    }
    .goods-image-box {
        width: 50px;
        height: 50px;
        align-content: center;
        border-radius: 50px;
        margin-left: 10px;
        background-size: contain;
    }

    .item {
        /*height: 50px;*/
        line-height: 60px;
    }

    .pagination {
        padding-top: 20px;
        text-align: right;
    }

    .huZhao {
        width: 100%;
        height: 150px;
        background-size: contain;
        background-repeat: no-repeat;
        cursor: pointer;
    }
    .header{
        font-size: 14px;
        font-weight: 500;
    }
    .photoUrl{
        width:40px;
        height: 40px;
        border-radius: 50% 50%;
        vertical-align: middle;
        background: #eee;
        position: relative;
        margin-right: 5px;
        img{
            width: 26px;
            height: 23px;
            position: absolute;
            left: 50%;
            top: 50%;
            margin-top: -11.5px;
            margin-left: -13px;
        }
    }

    .preview{
        color: @color-primary;
        cursor: pointer;
        text-align: center;
        display: inline-block;
        box-sizing: border-box;
        transition: .15s;
        text-decoration: none;
    }
</style>
