<template>
    <div>

        <div class="table_box" v-show="!detailsIsShow&&!userIsShow">
            <el-row align="middle" type="flex"
                    style="background: white;padding-left: 20px;padding-top: 10px;padding-bottom: 10px;margin-bottom: 20px">
                <el-col :span="5">
                    <div class="keyword">
                        <span>{{$t("m.customer.guanjiangci")}}：</span>
                        <el-input v-model="keywords" :placeholder="$t('m.customer.qingshuruguanjianci')" style="height: 40px;"></el-input>
                    </div>
                </el-col>
<!--                <el-col :span="5">-->
<!--                    <div class="keyword">-->
<!--                        <span>{{$t("m.customer.qudao")}}：</span>-->
<!--                        <el-select :placeholder="$t('m.customer.qingxuanze')" v-model="selectPosition">-->
<!--                            <el-option-->
<!--                                    v-for="item in channels"-->
<!--                                    :key="item.id"-->
<!--                                    :label="item.channel"-->
<!--                                    :value="item.id">-->
<!--                            </el-option>-->
<!--                        </el-select>-->
<!--                    </div>-->
<!--                </el-col>-->
                <el-col :span="10" :offset="1">
                    <div class="keyword">
                        <span>{{$t("m.customer.zhuceshijian")}}：</span>
                        <el-date-picker
                                v-model="time"
                                value-format="yyyy-MM-dd"
                                type="daterange"
                                align="right"
                                unlink-panels
                                clearable
                                :range-separator="$t('m.pubilc.zhi')"
                                :start-placeholder="$t('m.customer.kaishishijian')"
                                :end-placeholder="$t('m.customer.jieshushijiang')">
                        </el-date-picker>
                    </div>
                </el-col>
                <el-col :span="2" align="end">
                    <el-button type="primary" size="small" @click="search_keywords">{{$t("m.pubilc.sousuo")}}</el-button>
                </el-col>
            </el-row>
            <el-row class="eltable">
                <el-table :data="tableData" style="width: 100%">
                    <el-table-column prop="name" :label="$t('m.customer.gongsi')" align="center"></el-table-column>
                    <el-table-column :label="$t('m.customer.tuandui')">
                        <template slot-scope="scope">
                            {{scaleList[scope.row.scale]}}
                        </template>
                    </el-table-column>
                    <el-table-column :label="$t('m.customer.hangye')">
                        <template slot-scope="scope">
                            <span v-for="(item,index) in industryList"
                                  :key="index" v-if="item.value==scope.row.industry">
                                {{item.label}}
                            </span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="createAccountName" :label="$t('m.customer.zhuceren')"></el-table-column>
                    <el-table-column prop="phone" :label="$t('m.customer.shoujuhao')">
                    </el-table-column>
                    <el-table-column prop="email" :label="$t('m.customer.youxian')">
                    </el-table-column>
                    <el-table-column prop="createTime" :label="$t('m.customer.zhuceshijian')">
                    </el-table-column>
                    <el-table-column align="center" prop="memberCount" :label="$t('m.customer.chengyuan')"></el-table-column>
                    <el-table-column align="center" :label="$t('m.customer.xiaochengxu')">
                        <template slot-scope="scope">
                            <el-tooltip placement="bottom">
                                <div slot="content">已支付 {{scope.row.paidMiniProCount}} 个小程序<br/>
                                    已取消 {{scope.row.canceledMiniProCount}} 个小程序<br/>
                                    已退款 {{scope.row.refundedMiniProCount}} 个小程序<br/>
                                    总共 {{scope.row.miniProCount}} 个小程序</div>
                                <span>{{scope.row.paidMiniProCount}}/{{scope.row.canceledMiniProCount}}/{{scope.row.refundedMiniProCount}}/{{scope.row.miniProCount}}</span>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column :label="$t('m.pubilc.caozuo')" width="80" align="center">
                        <template slot-scope="scope">
                            <el-button type="text" size="mini" @click="check(scope.row)">{{$t("m.pubilc.chakan")}}</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </el-row>
            <el-row type="flex" style="justify-content: flex-end;margin: 15px 0 0;">
                <el-pagination class="pagination" background layout="total,prev, pager, next" :total="total"
                               @current-change='current_change' style="margin-top: 10px">
                </el-pagination>
            </el-row>
        </div>
        <enterpriseDetails v-if="detailsIsShow" :curretId="curretId"
                           @handleReturn="handleReturn"
        @goUser="goUser"></enterpriseDetails>
        <listDetails v-if="userIsShow" :currentId="currentuserId" @handleReturn="handleRet"></listDetails>
    </div>
</template>

<script>
    import {
        getMerchantList,
        getMerchantAccountList,
        getMiniProListByMerchantId
    } from 'request/api/client_list.js';
    import enterpriseDetails from "./details";
    import listDetails from "../list/details";


    export default {
        data() {
            return {
                userIsShow:false,
                currentuserId:null,
                selectPosition: null,
                pageNum: 1,
                pageSize: 10,
                tableData: [],
                total: 0,
                keywords: '',
                channels: [],
                time: [],
                scaleList:[
                    "1-30人","30-60人","60-100人"
                ],
                industryList:[
                    {
                        label: '互联网',
                        value: '1'
                    },
                    {
                        label: '消费生活',
                        value: '2'
                    },
                    {
                        label: '社交',
                        value: '3'
                    },
                    {
                        label: '资讯',
                        value: '4'
                    },
                    {
                        label: '传媒',
                        value: '5'
                    },
                    {
                        label: '企业服务',
                        value: '6'
                    },
                    {
                        label: '教育',
                        value: '7'
                    },
                    {
                        label: '医疗',
                        value: '8'
                    },
                    {
                        label: '房产',
                        value: '9'
                    },
                    {
                        label: '金融',
                        value: '10'
                    },
                    {
                        label: '娱乐',
                        value: '11'
                    },
                    {
                        label: '服务',
                        value: '12'
                    },
                    {
                        label: '生产制造',
                        value: '13'
                    },
                    {
                        label: '其他',
                        value: '14'
                    },
                ],
                detailsIsShow:false,
                curretId:null,
            }
        },
        components:{
            enterpriseDetails,
            listDetails
        },
        mounted() {
            // this.selectChannelAll()
            this.queryPage()
        },
        methods: {
            check(e) {
                this.curretId = e.id
                this.detailsIsShow = true

            },
            handleReturn(){
                this.curretId = null
                this.detailsIsShow = false
            },
            queryPage(pagenum = 1) {
                console.log(pagenum)
                let params = {
                    pageNum: pagenum,
                    pageSize: this.pageSize,
                }
                if(this.time&&this.time.length>0){
                    params.startRegisterTime=this.time[0]
                    params.endRegisterTime=this.time[1]
                }
                if(this.keywords.length>0){
                    params.keyword =  this.keywords
                }
                getMerchantList(params).then(res => {
                    if (res.code == 200) {
                        // let tableData =
                        // if(tableData.length>0){
                        //     tableData.forEach(item=>{
                        //         item.accountNum = 0
                        //         item.miniProNum = 0
                        //         item.accountList = []
                        //         // getMerchantAccountList({
                        //         //     merchantId:item.id,
                        //         //     pageNum:"1",
                        //         //     pageSize:"1",
                        //         // }).then(res=>{
                        //         //     item.accountNum = res.data.total
                        //         //     item.accountList = res.data.list
                        //         // })
                        //         // getMiniProListByMerchantId({
                        //         //     merchantId:item.id,
                        //         //     pageNum:"1",
                        //         //     pageSize:"1",
                        //         // }).then(res=>{
                        //         //     item.miniProNum = res.data.total
                        //         // })
                        //     })
                        // }
                        this.tableData = res.data.list
                        this.total = res.data.total
                        this.$forceUpdate()
                    }
                })
            },
            selectChannelAll() {
                findAllChannel({}).then(res => {
                    console.log(res)
                    if (res.code == 200) {
                        this.channels.push({
                            id: -1,
                            channel: this.$t("m.customer.all")
                        })
                        for (let i = 0; i < res.data.length; i++) {
                            this.channels.push({
                                id: res.data[i].id,
                                channel: res.data[i].channel
                            })
                        }
                    } else {
                        this.channels.push({
                            id: -1,
                            channel: this.$t("m.customer.all")
                        })
                    }
                })
            },
            // 分页改变事件
            current_change(e) {
                this.pageNum = e
                this.queryPage(e)
            },
            // 搜索关键词事件
            search_keywords() {
                this.queryPage()
            },
            goUser(item){
                this.userIsShow = true
                this.detailsIsShow = false
                this.currentuserId = item.accountId
            },
            handleRet(){
                this.userIsShow = false
                this.detailsIsShow = true
            },
        },

    }
</script>

<style scoped lang="less">
    @import "../../../assets/style/index";
    .table_box {
        padding: 15px;
        margin: auto;
        background: #fff;
    }

    .search_box {
        width: 100%;
        height: 50px;
        display: flex;
        align-items: center;
    }

    .keyword {
        height: 50px;
        display: flex;
        align-items: center;
        margin-right: 10px;
    }

    .keyword span {
        width: 80px;
        text-align: center;
    }

    .goods-image-box {
        width: 40px;
        height: 40px;
        align-content: center;
        border-radius: 40px;
        margin-left: 20px;
        background-size: contain;
        background-repeat: no-repeat;
    }
</style>
